import {getContextProperties, trackDOMEvent, trackSegmentEvent} from '~/components/shared/tracking/segmentAnalytics';
import {parseToRelativeTime,} from '../../shared/dateUtilities';

/**
 * Checks if two dates are the same
 * @param date1
 * @param date2
 * @return {boolean}
 */
function isDate(date1, date2) {
  return date1.toDateString() === date2.toDateString();
}

const convertTZ = (date, tzString) => {
  return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", { timeZone: tzString }));
}

/**
 * Helper function that filters the slots that are on a particular day
 * @param slots {Array[{object}]}
 * @param date {Date}
 * @param card {element}
 * @return {Array[{object}]}
 */
const slotsOnDate = (slots, date, card) => {
  const timezone = card.dataset.timezoneName;
  const filtered_slots = [];

  for (let i = 0; i < slots.length; i++) {
    let slotDate = new Date(slots[i].appointment_date);
    if (slotDate.getUTCDate() === date.getUTCDate()) {
      slotDate = convertTZ(slotDate, timezone);
    }
    if (isDate(slotDate, date)) {
      filtered_slots.push(slots[i]);
    }
  }

  return filtered_slots;
}

/**
 * Get all the slots for today
 * @param slots {Array[{Object}]}
 * @return {Array[{Object}]}
 */
const slotsToday = (slots, card) => {
  const todaysDate = new Date();
  return slotsOnDate(slots, todaysDate, card);
}

/**
 * Get all the slots for tomorrow
 * @param slots {Array[{Object}]}
 * @param card {element}
 * @return {Array[{Object}]}
 */
const slotsTomorrow = (slots, card) => {
  const today = new Date();
  const tomorrow = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);
  let tomorrowSlots =  slotsOnDate(slots, tomorrow, card);
  // we only want the first four slots
  tomorrowSlots = tomorrowSlots.slice(0, 4);
  return tomorrowSlots;
}

const getSegmentProperties = (card) => {
  let props = '';
  if (card.dataset.segmentCardRank) {
    props += `data-segment-card-rank="${card.dataset.segmentCardRank}" `;
  }
  if (card.dataset.segmentLocationId) {
    props += `data-segment-location-id="${card.dataset.segmentLocationId}"`;
  }
  return props;
};

const buildLink = (card, href, linkText, params, classes, appointment_date) => {

  const slotDate = new Date(appointment_date);
  const today = new Date();
  const tomorrow = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);
  const isToday = today.getDate() == slotDate.getDate();
  const isTomorrow = slotDate.getFullYear() && tomorrow.getMonth() == slotDate.getMonth() && tomorrow.getDate() == slotDate.getDate()


  let segmentAction = 'Click bookable time'

  if (appointment_date == 'view more') {
    segmentAction = 'Click view more'
  } else if (isToday) {
    segmentAction += ' - today'
  } else if (isTomorrow) {
    segmentAction += ' - tomorrow'
  }

  return `<a
        ${getSegmentProperties(card)}
        data-segment-event="Directory - CDP Card"
        data-segment-action="${segmentAction}"
        class="${classes}"
        onclick="this.href=this.href + '${params}'"
        onauxclick="this.href=this.href + '${params}'"
        href="${href}"
        target="_blank"
        >
            ${linkText}
        </a>`;
};

const buildTimeSlotButtons = (card, slots, buttonClasses ) => {
  let buttons = '';
  const directBookingHref = `${card.dataset.directBookingLink}?rel=nofollow`;
  const bookingLinkParams = card.dataset.bookingLinkParams;

  slots.forEach((slot) => {
    const time = parseToRelativeTime(slot.appointment_date, card.dataset.timezoneName);
    const params = `&slot=${encodeURIComponent(slot.appointment_date)}&${bookingLinkParams}`;
    buttons += buildLink(card, directBookingHref, time, params, buttonClasses, slot.appointment_date);
  });

  return buttons;
};

const newBookingTimesSection = (card, date, slots, options) => {
  const buttons = buildTimeSlotButtons(card, slots, options.buttonClasses, options.seeMoreText);
  return `<h4 class="bookable-times__title">${date}</h4>
          <div class="bookable-times__buttons-wrapper" data-bookable-buttons-wrapper>
            ${buttons}
          </div>`;
}

const addTrackingToCardBookingLinks = (card) => {
  const buttonContainers = card.querySelectorAll('.bookable-times__buttons-wrapper');
  buttonContainers.forEach(container => {
    container.querySelectorAll('a').forEach((el) => {
      el.addEventListener('click', (event) =>
        trackDOMEvent(event),
      );
    });
  });
}

const insertBookingTimesContent = (card, slots, options) => {
  const todaySlots = slotsToday(slots, card);
  const tomorrowSlots = slotsTomorrow(slots, card);
  const container = card.querySelector('[data-bookable-times-container]');
  let bookingTimesContent = '';

  if (todaySlots && todaySlots.length > 0) {
    bookingTimesContent += newBookingTimesSection(card, 'TODAY', todaySlots, options)
  }

  if (tomorrowSlots && tomorrowSlots.length > 0 && todaySlots.length < 4) {
    bookingTimesContent += newBookingTimesSection(card, 'TOMORROW', tomorrowSlots, options)
  }

  const directBookingHref = `${card.dataset.directBookingLink}?rel=nofollow`;
  const bookingLinkParams = card.dataset.bookingLinkParams;
  bookingTimesContent += buildLink(card, directBookingHref, options.seeMoreText, `&${bookingLinkParams}`, 'see-more-times', 'view more');

  container.innerHTML = bookingTimesContent;
  addTrackingToCardBookingLinks(card);
}

const insertErrorMessage = (card) => {
  const buttonContainer = card.querySelector('.bookable-times__buttons-wrapper');
  buttonContainer.classList.add('error-message');
  const href = card.dataset.permalink;
  const cdp_params = card.dataset.params;
  buttonContainer.innerHTML = `<h5 class="bookable-times__message-title">
                                  Can't get current appointments.
                                  <a class="see-more-times" href="${href}" onclick="this.href=this.href + '?${cdp_params}'" onauxclick="this.href=this.href + '?${cdp_params}'">+ See booking page</a>
                                </h5>`;
}

const insertActFastSocialProofAttribute = (card, slots) => {
  const socialProof = card.querySelector('[data-social-proof]');
  const isTop20 = JSON.parse(card.dataset.top_20);
  if (socialProof && !isTop20) {
    socialProof.classList.remove('social-proof-placeholder');
    socialProof.classList.add('unified-listing-card__social-proof');
    const icon = card.dataset.hourglassIcon;
    const message = `<strong>Act fast!</strong> Only ${slots.length} online slots left today`;
    socialProof.innerHTML = `${icon}<p>${message}</p>`;
    card.dataset.segmentSocialProof = message;
  }
};

const updateCardWithAPIData = (data) => {
  const controllerEl = document.querySelector('[data-booking-times-controller]');
  const buttonClasses = controllerEl.dataset.buttonClasses || 'primary-button sm';
  const seeMoreText = controllerEl.dataset.seeMoreText || 'View more';

  const locationId = data.location_id;
  let card = controllerEl.querySelector(`.unified-listing-card--bookable.listing-card[data-segment-location-id="${locationId}"]`);

  if (!card) {
    card = controllerEl.querySelector(`.unified-listing-card--bookable[data-segment-location-id="${locationId}"]`);
  }
  if (!card) {
    return;
  }

  card.dataset.slotsLoaded = 'true';
  const slots = data.slots;

  if (slots && slots.length > 0) {
    insertBookingTimesContent(card, slots, { buttonClasses, seeMoreText });
    const todaysSlots = slotsToday(slots, card);
    if (todaysSlots.length < 5 && todaysSlots.length > 0) {
      insertActFastSocialProofAttribute(card, todaysSlots);
    }
  } else {
    insertErrorMessage(card);
  }
}

const bookingTimesLoaded = () => {
  const bookableCounts = {
    nearby_bookable_same_day_availability: 0,
    nearby_bookable_next_day_availability: 0,
  }
  document.querySelectorAll('[data-booking-times-controller] [data-booking-times-card] .bookable-times__title').forEach(title => {
    if (title.textContent.includes('TODAY')) {
      bookableCounts.nearby_bookable_same_day_availability += 1;
    } else if (title.textContent.includes('TOMORROW')) {
      bookableCounts.nearby_bookable_next_day_availability += 1;
    }
  });

  const properties = getContextProperties('Directory - Slots')

  properties.nearby_bookable_same_day_availability = bookableCounts.nearby_bookable_same_day_availability
  properties.nearby_bookable_next_day_availability = bookableCounts.nearby_bookable_next_day_availability
  properties.results_contain.nearby_bookable_same_day_availability = bookableCounts.nearby_bookable_same_day_availability
  properties.results_contain.nearby_bookable_next_day_availability = bookableCounts.nearby_bookable_next_day_availability

  trackSegmentEvent('Directory - Slots', properties)
}

const insertErrorMessages = (id_hashes) => {
  id_hashes.forEach((id_hash) => {
    const card = document.querySelector(`.unified-listing-card--bookable[data-segment-location-id="${id_hash}"]`);
    insertErrorMessage(card);
  })
}

const useHttpForBookingTimes = (locationDataObject) => {
  const locationDataJSON = JSON.stringify(locationDataObject);
  const exceptionURL = `/dir/load-booking-times?location_data=${locationDataJSON}&source=directory&page=${window.location.pathname}`
  const xmlHttp = new XMLHttpRequest();
  xmlHttp.open('GET', exceptionURL, false);
  xmlHttp.addEventListener('load', () => {
    const status = xmlHttp.status;
    if (status === 200) {
      const data = JSON.parse(xmlHttp.response);
      const status = data.status;
      if (status === 'success') {
        const location_data = data.location_data;
        location_data.forEach((data) => {
          updateCardWithAPIData(data);
        })
        bookingTimesLoaded();
      } else {
        insertErrorMessages(id_hashes);
      }
    } else {
      insertErrorMessages(id_hashes);
    }
  });

  xmlHttp.send();

}

const reportError = (id_hashes) => {
  const exceptionURL = `/dir/booking-times-failed?id_hashes=${id_hashes.join()}&source=directory&page=${window.location.pathname}}`
  const xmlHttp = new XMLHttpRequest();
  xmlHttp.open('GET', exceptionURL, false);
  xmlHttp.send();
}

export { bookingTimesLoaded, updateCardWithAPIData, useHttpForBookingTimes, reportError }


